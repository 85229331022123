import React from "react"

import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/layout"
import poster from "../assets/2019_Poster_MarianeSchneider_Kyoto2019.pdf" 


const Kyoto2019 = () => (
	<Layout>
		<Container className="mt-5">
			<h1 className="text-center">Kyoto University International Symposium 2019</h1>
			<Row className="mt-5 justify-content-center">
				<Col xs={10} md={8}>
					<p>
						I am invited to the <em>Kyoto University International Symposium</em> to present
						my latest result. If you want to know more about my current research, you
						can download the poster I will be presenting using the link below.
					</p>
					<p>
						If you are also joining the symposium and want to meet, <strong>I will present
						my results on the 26th of November</strong>.
					</p>
					<p className="posterDownload text-center font-weight-bold">
						<i className="fa fa-arrow-right"/>
						<a className="font-weight-bold" href={poster}>  Download the poster  </a> 
						<i className="fa fa-arrow-left"/>
					</p>
				</Col>
			</Row>
		</Container>
	</Layout>
)

export default Kyoto2019
